import React, { useEffect, useLayoutEffect } from 'react';
import './meu-perfil.page.scss';
import icoProfile from '../../assets/header/profile.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useInput } from '../../hooks/useInput.hook';
import { AccountCircle, Cancel, CloudUpload } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockIcon from '@mui/icons-material/Lock';
import ListIcon from '@mui/icons-material/List';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useHistory } from 'react-router-dom';
import UsuarioModel from '../../models/usuario.model';
import { getAuthUser } from '../../services/auth';
import { LoadingService } from '../../services/loading.service';
import { AtualizarUsuarioService, DeleteUsuarioService, UploadImagemService } from '../../services/api-usuario.service';
import { SnackService } from '../../services/snack.service';
import SaveIcon from '@mui/icons-material/Save';
import ModalConfirmComponent from '../../components/modal-confirm/modal-confirm.component';
import { UsuarioEfetuouLogOutService, UsuarioEfetuouLoginService } from '../../services/usuario-efetuou-login.service';

function MeuPerfilPage() {

  const [usuario, setUsuario] = React.useState<UsuarioModel>(getAuthUser());

  const [fileSelected, setFileSelected] = React.useState<File | undefined>();
  const [foto, setFoto] = React.useState<string | undefined>(usuario.foto);
  const [refFile, setRefFile] = React.useState<any>();

  const [editarUsuario, setEditarUsuario] = React.useState<boolean>(false);

  const { value: nomeInputText, bind: bindNomeInputText, setValue: setNomeInputText } = useInput(usuario.first_name);
  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput(usuario.user_email);
  const { value: telefoneInputText, bind: bindTelefoneInputText, setValue: setTelefoneInputText } = useInput(usuario.telefone);
  const { value: enderecoInputText, bind: bindEnderecoInputText, setValue: setEnderecoInputText } = useInput(usuario.endereco);
  const { value: senhaInputText, bind: bindSenhaInputText, setValue: setSenhaInputText } = useInput('');

  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);

  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      uploadFile();
    }, 200);
  }, [fileSelected]);

  const GoPage = (page: string) => {
    history?.push({
      pathname: '/' + page
    })
  }

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setFileSelected(fileList[0]);
  };

  const uploadFile = () => {
    if (fileSelected) {
      const formData = new FormData();
      formData.append("file", fileSelected, fileSelected.name + '-777-' + usuario.ID);
      formData.append('action', 'md_support_save');

      UploadImagemMinhaConta(formData);
    }
  };

  const UploadImagemMinhaConta = (formData: any) => {

    LoadingService.sendLoadingAtivo(true);

    UploadImagemService(formData, usuario.ID).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        if (res.data.error) {
          SnackService.sendMSG(res.data.error);
        } else {
          setFoto(res.data.foto);
          setFileSelected(undefined);
          SnackService.sendMSG('Imagem alterada!');

          let tempUser = usuario;
          tempUser.foto = res.data.foto;
          localStorage.setItem("user", JSON.stringify(tempUser));
          setUsuario(tempUser);
          UsuarioEfetuouLoginService.sendMSG(tempUser);
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const AtualizarUsuario = () => {

    if (nomeInputText === '') {
      SnackService.sendMSG('Informe o nome completo!');
      return;
    } else if (emailInputText === '') {
      SnackService.sendMSG('Informe o e-mail!');
      return;
    } else if (telefoneInputText === '') {
      SnackService.sendMSG('Informe o telefone!');
      return;
    } else if (enderecoInputText === '') {
      SnackService.sendMSG('Informe ao endereço!');
      return;
    } else if (senhaInputText === '') {
      SnackService.sendMSG('Informe a senha!');
      return;
    }

    LoadingService.sendLoadingAtivo(true);

    let tempUser = usuario;

    tempUser.first_name = nomeInputText;
    tempUser.telefone = telefoneInputText;
    tempUser.endereco = enderecoInputText;
    tempUser.user_pass = senhaInputText;

    AtualizarUsuarioService(tempUser).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        if (res.data.error) {
          SnackService.sendMSG(res.data.error);
        } else {
          tempUser.user_pass = '';
          setUsuario(tempUser);
          localStorage.setItem("user", JSON.stringify(tempUser));
          SnackService.sendMSG('Usuário atualizado!');
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const DeleteUsuario = (val) => {
    if (val != 'Sim') {
      return;
    }
    LoadingService.sendLoadingAtivo(true);
    DeleteUsuarioService({ user_id: usuario.ID }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        // user deletado
        UsuarioEfetuouLogOutService.sendMSG(true);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <section className="meu-perfil page">
        <div className="container">
          <div className="box-foto flex flex-ch flex-wr">
            <input
              accept="image/*"
              id="photo"
              name="photo"
              type="file"
              style={{ display: 'none' }}
              multiple={false}
              onChange={handleImageChange}
              ref={input => setRefFile(input)}
            />
            {
              foto ?
                <img src={foto} alt='Profile' />
                :
                <img src={icoProfile} alt='Profile' />
            }
            <div className="edit">
              {
                fileSelected ?
                  <Button className='bt-editar' onClick={uploadFile}>
                    <CloudUpload />
                  </Button>
                  :
                  <Button className='bt-editar' onClick={() => refFile.click()}>
                    <EditIcon />
                  </Button>
              }

              {
                fileSelected ?
                  <span>enviar foto</span>
                  :
                  <span>editar foto</span>
              }

            </div>
          </div>
          <div className="campos">
            <TextField {...bindNomeInputText} variant="standard"
              InputProps={{
                readOnly: !editarUsuario,
                placeholder: 'Nome',
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Button className='bt-editar' onClick={() => {
                      editarUsuario ?
                        AtualizarUsuario()
                        :
                        setEditarUsuario(!editarUsuario)
                    }
                    }>
                      {
                        editarUsuario ?
                          <SaveIcon />
                          :
                          <EditIcon />
                      }
                    </Button>
                    {
                      editarUsuario &&
                      <Button className='bt-editar' onClick={() => { setEditarUsuario(false) }}>
                        <Cancel />
                      </Button>
                    }
                  </InputAdornment>
                )
              }} />
            <TextField {...bindEmailInputText} variant="standard"
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                )
              }} />
            <TextField {...bindTelefoneInputText} variant="standard"
              InputProps={{
                readOnly: !editarUsuario,
                placeholder: 'Telefone',
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Button className='bt-editar' onClick={() => {
                      editarUsuario ?
                        AtualizarUsuario()
                        :
                        setEditarUsuario(!editarUsuario)
                    }
                    }>
                      {
                        editarUsuario ?
                          <SaveIcon />
                          :
                          <EditIcon />
                      }
                    </Button>
                    {
                      editarUsuario &&
                      <Button className='bt-editar' onClick={() => { setEditarUsuario(false) }}>
                        <Cancel />
                      </Button>
                    }
                  </InputAdornment>
                )
              }} />
            <TextField {...bindEnderecoInputText} variant="standard"
              InputProps={{
                readOnly: !editarUsuario,
                placeholder: 'Endereço',
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Button className='bt-editar' onClick={() => {
                      editarUsuario ?
                        AtualizarUsuario()
                        :
                        setEditarUsuario(!editarUsuario)
                    }
                    }>
                      {
                        editarUsuario ?
                          <SaveIcon />
                          :
                          <EditIcon />
                      }
                    </Button>
                    {
                      editarUsuario &&
                      <Button className='bt-editar' onClick={() => { setEditarUsuario(false) }}>
                        <Cancel />
                      </Button>
                    }
                  </InputAdornment>
                )
              }} />
            <TextField {...bindSenhaInputText} variant="standard"
              InputProps={{
                readOnly: !editarUsuario,
                type: 'password',
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Button className='bt-editar' onClick={() => {
                      editarUsuario ?
                        AtualizarUsuario()
                        :
                        setEditarUsuario(!editarUsuario)
                    }
                    }>
                      {
                        editarUsuario ?
                          <SaveIcon />
                          :
                          <EditIcon />
                      }
                    </Button>
                    {
                      editarUsuario &&
                      <Button className='bt-editar' onClick={() => { setEditarUsuario(false) }}>
                        <Cancel />
                      </Button>
                    }
                  </InputAdornment>
                )
              }} />
            <div className="bts">
              <Button className='bt-editar' onClick={() => { GoPage('preferencia') }}>
                <ListIcon /> Minhas preferências
              </Button>
              <Button className='bt-editar' onClick={() => { setOpenModalConfirm(true) }}>
                <DeleteOutlineIcon /> Excluir conta
              </Button>
              <Button className='bt-editar' onClick={() => {
                UsuarioEfetuouLogOutService.sendMSG(true);
              }}>
                <CancelPresentationIcon /> Sair do aplicativo
              </Button>
            </div>
          </div>
        </div>
      </section>
      <ModalConfirmComponent
        selectedValue={'Não'}
        open={openModalConfirm}
        onClose={(val) => {
          setOpenModalConfirm(false);
          DeleteUsuario(val);
        }}
      />
    </>
  );
}

export default MeuPerfilPage;
