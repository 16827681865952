import React, { useLayoutEffect } from 'react';
import './noticias.page.scss';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import NoticiasModel from '../../models/noticias.model';
import { useHistory } from 'react-router-dom';
import { GetNoticiasService } from '../../services/eventos.service';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function NoticiasPage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listNoticias, setListNoticias] = React.useState<Array<NoticiasModel>>([]);
  const [acabouListNoticias, setAcabouListNoticias] = React.useState<boolean>(false);
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetNoticias();
    }, 500);
  }, []);

  const GetNoticias = () => {
    LoadingService.sendLoadingAtivo(true);
    GetNoticiasService({ user_email: 'email', idsNoticias: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListNoticias(listNoticias.concat(res.data.noticiasArr));
        if (res.data.noticiasArr.length < 3) {
          setAcabouListNoticias(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar noticias na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const AcessarNoticia = (alias: string) => {
    history?.push({
      pathname: `/noticia/${alias}`
    })
  }

  return (
    <>
      <section className="page noticias">
        <div className="head">
          <div className="container">
            <div className="flex flex-cv flex-sp">

            </div>
          </div>
        </div>
        <div className="container">
          <div className="cards-noticias flex flex-ch flex-wr">
            {
              listNoticias ? listNoticias.map((item, indexVideo) => (
                <div
                  className="card-noticias flex"
                  key={indexVideo}
                  onClick={() => { AcessarNoticia(item.alias) }}
                >
                  <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                  <div className="txt">
                    <h2>{item.titulo}</h2>
                    <div className="descricao" dangerouslySetInnerHTML={{ __html: item.descricao ? item.descricao : '' }}></div>
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListNoticias) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt cinza' onClick={GetNoticias}>
                <AddIcon />
                notícias...
              </Button>
            </div>
          }
        </div>
      </section>
    </>
  );
}

export default NoticiasPage;
