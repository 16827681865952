import React, { useEffect, useState } from 'react';
import './lojas.page.scss';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import { LoadingService } from '../../services/loading.service';
import { PageLojasService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import LojasModel from '../../models/lojas.model';
import markerIcon from '../../assets/mapa/marker-icon.png';
import markerLojaIcon from '../../assets/mapa/marker-loja-icon.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import vendasIcon from '../../assets/mapa/servicos/vendas.svg';
import manutencaoIcon from '../../assets/mapa/servicos/manutencao.svg';
import frotasIcon from '../../assets/mapa/servicos/frotas-corporativo.svg';
import divisaoIcon from '../../assets/mapa/servicos/divisao-de-veiculos-sspeciais.svg';


import L from 'leaflet';
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Drawer, List, ListItem, ListItemButton, SwipeableDrawer, TextField } from '@mui/material';
import { FindInPage } from '@mui/icons-material';
import { useInput } from '../../hooks/useInput.hook';
const icoLocation = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon,
  popupAnchor: [-0, -0],
  iconSize: [42, 41],
});
const icoLoja = new L.Icon({
  iconUrl: markerLojaIcon,
  iconRetinaUrl: markerLojaIcon,
  popupAnchor: [-0, -0],
  iconSize: [41, 41],
});

function LojasPage() {

  const [listLojas, setListLojas] = React.useState<Array<LojasModel>>([]);
  const [listLojasProximas, setListLojasProximas] = React.useState<Array<LojasModel>>([]);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [openDrawerDir, setOpenDrawerDir] = React.useState<boolean>(false);
  const [position, setPosition] = useState<any>(null);
  const [positionNavegador, setPositionNavegador] = useState<any>(null);
  const [mapGlobal, setMapGlobal] = useState<any>(null);
  const { value: buscaInputText, bind: bindBuscaInputText, setValue: setBuscaInputText } = useInput('');
  const [buscaLocalAtivo, SetBuscaLocalAtivo] = React.useState<boolean>(false);
  let history = useHistory();

  let locationStorage: any = localStorage.getItem('location');
  let locationApp: any = JSON.parse(locationStorage);

  useEffect(() => {
    setTimeout(() => {
      GetLojas();
    }, 500);
  }, []);

  const BuscarLocalizacao = () => {

    SetBuscaLocalAtivo(true);
    setOpenDrawer(false);
    LoadingService.sendLoadingAtivo(true);

    const cep = buscaInputText; // CEP de São Paulo - SP
    const apiKey = '251bd9765b314a99ab6f973d27e73da9';

    fetch(`https://api.opencagedata.com/geocode/v1/json?q=${cep}&key=${apiKey}`)
      .then(response => response.json())
      .then(data => {
        LoadingService.sendLoadingAtivo(false);
        const { lat, lng } = data.results[0].geometry;
        let latLng: any = { lat: lat, lng: lng };
        setPosition(latLng);
      })
      .catch(error => {
        console.error('Erro ao obter dados de geolocalização:', error);
        LoadingService.sendLoadingAtivo(false);
      });
  }


  function LocationMarker() {

    const map = useMapEvents({
      click() {
        //map.locate()
      },
      locationfound(e: any) {

        if (!buscaLocalAtivo) {
          if (localStorage.getItem('location') != 'null') {
            let latlng: any = { lat: locationApp.coords.latitude, lng: locationApp.coords.longitude };
            setPosition(latlng)
            //map.flyTo(latlng, map.getZoom())
          } else {
            setPosition(e.latlng);
            setPositionNavegador(e.latlng);
            //map.flyTo(e.latlng, map.getZoom());
          }
        }

        LoadingService.sendLoadingAtivo(false);

      }
    })

    useEffect(() => {
      if (position != null) {
        map.flyTo(position, map.getZoom());
      }
    }, [position]);

    setMapGlobal(map);

    map.whenReady(() => {
      map.locate();
    });

    return position === null ? null : (
      <Marker icon={icoLocation} position={position}>
        <Popup>Você está aqui.</Popup>
      </Marker>
    )
  }

  const GetLojas = () => {
    LoadingService.sendLoadingAtivo(true);
    PageLojasService().then(res => {
      //LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListLojas(res.data);
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const LojasProximas = () => {
    let newArr: LojasModel[] = [];

    listLojas.map(item => {
      let latLngLoja = { lat: item.latitude, lng: item.longitude };
      let distance = mapGlobal.distance(position, latLngLoja);
      console.log(distance, ' <- distance')
      if (distance <= 100000) {
        let newItem: LojasModel = { ...item, distancia: (distance / 1000) };
        newArr.push(newItem);
      }
    })

    setListLojasProximas(newArr);

    setOpenDrawerDir(true);
    setOpenDrawer(false);
  }

  const MinhaLocalizacao = () => {
    if (localStorage.getItem('location') != 'null') {
      let latlng: any = { lat: locationApp.coords.latitude, lng: locationApp.coords.longitude };
      setPosition(latlng)
    } else {
      setPosition(positionNavegador);
    }
    SetBuscaLocalAtivo(false);
    setOpenDrawer(false);
  }

  return (
    <>
      <section className="lojas page">
        <div className="container">
          <h1 className='flex flex-cv flex-sp'>
            <div className="esq flex flex-cv">
              <Button className='bt-seta' onClick={() => { history.goBack() }}>
                <ArrowBackIosIcon />
              </Button>
              Encontre a concessionárias mais próxima
            </div>
            <Button disableFocusRipple className="flex flex-cv" onClick={() => { setOpenDrawer(true) }}>
              <SearchIcon /> buscar
            </Button>
          </h1>
          <MapContainer
            center={[-23.5489, -46.6388]}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
              listLojas.map((item, indexLojas) => (
                <Marker icon={icoLoja} key={indexLojas} position={[item.latitude, item.longitude]}>
                  <Popup>
                    <h3>{item.nome}</h3>
                    <p>{item.endereco}</p>
                    <p>{item.telefone}</p>
                  </Popup>
                </Marker>
              ))
            }
            {
              (listLojas.length > 0) &&
              <LocationMarker />
            }
          </MapContainer>
        </div>
      </section>
      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
        className='lojas-drawer'
      >
        <Box
          sx={{ height: '50vh', width: '100%' }}
          role="presentation"
        // onClick={() => { setOpenDrawer(false) }}
        //onKeyDown={() => { setOpenDrawer(false) }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => { LojasProximas(); }}>
                Concessionárias próximas <ArrowForwardIosIcon />
              </ListItemButton>
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField {...bindBuscaInputText} label="Buscar cidade / cep" placeholder='Buscar cidade / cep' type="text" variant="standard" />
                <Button className="bt-busca" onClick={() => BuscarLocalizacao()}>
                  <SearchIcon />
                </Button>
              </Box>
            </ListItem>
          </List>
          {
            buscaLocalAtivo &&
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => { MinhaLocalizacao(); }}>
                  Minha localização <LocationOnIcon />
                </ListItemButton>
              </ListItem>
            </List>
          }
        </Box>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor='right'
        open={openDrawerDir}
        onClose={() => { setOpenDrawerDir(false) }}
        onOpen={() => { setOpenDrawerDir(true) }}
        className='lojas-drawer-dir'
      >
        <Box
          sx={{ height: '50vh', width: '100%' }}
          role="presentation"
          // onClick={() => { setOpenDrawerDir(false) }}
          onKeyDown={() => { setOpenDrawerDir(false) }}
        >
          <div className="container">
            <div className="flex">
              <Button onClick={() => { setOpenDrawerDir(false) }} className='bt-voltar'>
                <ArrowBackIosIcon /> Voltar
              </Button>
            </div>
            {
              listLojasProximas ?
                listLojasProximas.map((item, indexLojas) => (
                  <Accordion key={indexLojas}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="titulo">
                        <h3 className='flex flex-cv'>
                          <span>{indexLojas + 1}</span>
                          <b>{item.nome} <small>({item.distancia?.toFixed(2)} km)</small></b>
                        </h3>
                        <p><LocationOnIcon /> {item.endereco}</p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="txt">
                        {
                          item.telefone &&
                          <p><LocalPhoneIcon />{item.telefone} (Vendas)</p>
                        }
                        {
                          item.telefone_manutencao &&
                          <p><LocalPhoneIcon />{item.telefone_manutencao} (Manutenção)</p>
                        }
                        {
                          item.email_vendas &&
                          <p><MailOutlineIcon />{item.email_vendas} (Vendas)</p>
                        }
                        {
                          item.email_manutencao &&
                          <p><MailOutlineIcon />{item.email_manutencao} (Manutenção)</p>
                        }
                        {
                          item.servicos &&
                          <>
                            <h3>Serviços</h3>
                            {
                              item.servicos.map((itemServico, indexServico) => (
                                <>
                                  {
                                    itemServico == 'Vendas' &&
                                    <p key={indexServico}>
                                      <img src={vendasIcon} alt={itemServico} /> {itemServico}
                                    </p>
                                  }
                                  {
                                    itemServico == 'Manutenção' &&
                                    <p key={indexServico}>
                                      <img src={manutencaoIcon} alt={itemServico} /> {itemServico}
                                    </p>
                                  }
                                  {
                                    itemServico == 'Divisão de Veículos Especiais' &&
                                    <p key={indexServico}>
                                      <img src={divisaoIcon} alt={itemServico} /> {itemServico}
                                    </p>
                                  }
                                  {
                                    itemServico == 'Serviço EV' &&
                                    <p key={indexServico}>
                                      <img src={manutencaoIcon} alt={itemServico} /> {itemServico}
                                    </p>
                                  }
                                  {
                                    itemServico == 'Frotas e Corporativo' &&
                                    <p key={indexServico}>
                                      <img src={frotasIcon} alt={itemServico} /> {itemServico}
                                    </p>
                                  }
                                </>
                              ))
                            }
                          </>
                        }
                        {
                          item.site &&
                          <Button className='bt cinza' href={item.site} target='_blank'>
                            <ArrowRightAltIcon /> SITE DO CONCESSIONÁRIO
                          </Button>
                        }
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
                :
                <p>Nenhuma concessionária encontrada.</p>
            }
          </div>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default LojasPage;
