import { NavLink, useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import icoMenu from '../../assets/header/menu.svg';
import logoDefender from '../../assets/header/logo_defender_club.svg';
import icoProfile from '../../assets/header/profile.svg';
import './header.component.scss';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from "@mui/material";
import React, { useEffect } from "react";
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import HomeIcon from '@mui/icons-material/Home';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import UsuarioModel from "../../models/usuario.model";
import { UsuarioEfetuouLogOutService } from "../../services/usuario-efetuou-login.service";
import { UsuarioEfetuouLoginService } from "../../services/usuario-efetuou-login.service";
import { getAuthUser } from "../../services/auth";

function HeaderComponent() {

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>(getAuthUser());

  let history = useHistory();

  useEffect(() => {

    UsuarioEfetuouLoginService.getMSG().subscribe((usuarioService: any) => {
      setUsuarioLogado(usuarioService);
    });

    UsuarioEfetuouLogOutService.getMSG().subscribe((usLogout: any) => {
      if (usLogout == true) {
        Sair();
      }
    });

    let usLogado = localStorage.getItem("user");
    if (usLogado) {
      setUsuarioLogado(JSON.parse(usLogado));
    }
  }, []);

  const Sair = () => {
    localStorage.removeItem('user');
    history?.push({
      pathname: '/login'
    })
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: '/' + page
    })
  }

  return (
    <>
      <header className="flex flex-sp flex-cv">
        <Button onClick={() => { setOpenDrawer(true) }}>
          <img src={icoMenu} alt="Menu" />
        </Button>
        <Button className="bt-logo" onClick={() => { GoPage('home') }}>
          <img src={logoDefender} alt="logo Defender" />
          <small>v.1.1</small>
        </Button>
        <Button className="bt-profile" onClick={() => { GoPage('meu-perfil') }}>
          {
            usuarioLogado ?
              usuarioLogado.foto?.toString().indexOf('http') > -1 ?
                <img src={usuarioLogado.foto} alt="Profile" />
                :
                <img src={icoProfile} alt="Profile" />
              :
              <img src={icoProfile} alt="Profile" />
          }
        </Button>
        {/* <nav>
          <ul className="flex flex-sp flex-cv">
            <li>
              <NavLink exact={true} to="/home" activeClassName="active">
                <Button>Home</Button>
              </NavLink>
            </li>
            <li>
              <NavLink exact={true} to="/eventos" activeClassName="active">
                <Button>Eventos</Button>
              </NavLink>
            </li>
          </ul>
        </nav> */}
      </header>
      <SwipeableDrawer
        anchor='left'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => { setOpenDrawer(false) }}
          onKeyDown={() => { setOpenDrawer(false) }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <HomeIcon /> Home
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <HandshakeIcon /> Parceiros
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <CalendarMonthIcon /> Próximos eventos
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <LocationOnIcon /> Concessionárias
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ChatIcon /> Concierge
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => { GoPage('tutorial') }}>
                <PhoneIphoneIcon /> Tutorial
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => { GoPage('preferencia') }}>
                <PhoneIphoneIcon /> Preferências
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <LockIcon /> POLÍTICA DE PRIVACIDADE
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={Sair}>
                <LogoutIcon /> Sair
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default HeaderComponent;
