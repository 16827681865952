import React, { useState } from 'react';
import './concierge.page.scss';
import OpenAIApi from 'openai';
import ChatCompletionRequestMessageRoleEnum from 'openai';
import MensagensChatModel from '../../models/mensagens.model';
import { Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import imgDefender from '../../assets/concierge/img-defender.png';
import imgUsuario from '../../assets/concierge/img-usuario.png';
import imgSend from '../../assets/concierge/send.svg';
import { LoadingService } from '../../services/loading.service';
//import OpenAIApi from 'openai';
//const { Configuration, OpenAIApi } = require("openai");


function ConciergePage() {

  // const configuration = new Configuration({
  //   apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  //   //dangerouslyAllowBrowser: true
  // });

  const openai = new OpenAIApi({
    apiKey: "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",//process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });
  const [prompt, setPrompt] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const [mensagens, setMensagens] = useState<MensagensChatModel[]>([
    {
      role: 'system',
      content: 'Olá =)',
    }
  ]);
  let history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    LoadingService.sendLoadingAtivo(true);
    setMensagens(current => [...current, { content: prompt, role: 'user' }]);
    try {
      const result: any = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            //stream: true,
            content: !!prompt ? prompt : 'Hello',
          }
        ],
        model: 'gpt-3.5-turbo',
      });
      //console.log("response", result.data.choices[0].text);
      setMensagens(current => [...current, result.choices[0].message]);
      setApiResponse(result.choices[0].message.content);
      setPrompt('');
      LoadingService.sendLoadingAtivo(false);
      console.log(result.choices);
      console.log(result.choices[0].message.content);

    } catch (e) {
      console.log(e);
      setApiResponse("Something is going wrong, Please try again.");
      LoadingService.sendLoadingAtivo(false);
    }
    setLoading(false);
  };

  return (
    <section className="concierge page">
      <div className="container">
        <h1 className='flex flex-cv flex-sp'>
          <div className="esq flex flex-cv">
            <Button className='bt-seta' onClick={() => { history.goBack() }}>
              <ArrowBackIosIcon />
            </Button>
            Concierge
          </div>
        </h1>
      </div>
      <div className="msgs">
        <div className="container">
          {
            mensagens.map((item, indexMsg) => (
              // <div key={indexMsg} className="box-msg">
              //   <h2>{item.role}</h2>
              //   <p>{item.content}</p>
              // </div>
              <>
                {
                  item.role == 'user' ?
                    <div className="box-msg usuario flex flex-bv flex-ri">
                      <div className="msg">
                        {item.content}
                      </div>
                      <img src={imgUsuario} alt="Defender" />
                    </div>
                    :
                    <div className="box-msg defender flex flex-bv">
                      <img src={imgDefender} alt="Defender" />
                      <div className="msg">
                        {item.content}
                      </div>
                    </div>
                }
              </>
            ))
          }
        </div>
      </div>
      <form onSubmit={handleSubmit} >
        <div className="container">
          <div className="flex flex-cv">
            <textarea
              value={prompt}
              rows={1}
              placeholder="Digite aqui"
              onChange={(e) => setPrompt(e.target.value)}
            ></textarea>
            <Button
              disabled={loading || prompt.length === 0}
              type='submit'>
              <img src={imgSend} alt="Enviar" />
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default ConciergePage;
