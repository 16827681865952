import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function GetEventosService(dto: { user_email: string, idsEventos: number[] }) {
    let url = '/get-eventos';
    return await api.post(url, dto, config);
}

export async function EventoService(dto: { alias: string }) {
    let url = '/get-evento';
    return await api.post(url, dto, config);
}

export async function GetVideosService(dto: { user_email: string, idsVideos: number[] }) {
    let url = '/get-videos';
    return await api.post(url, dto, config);
}

export async function GetNoticiasService(dto: { user_email: string, idsNoticias: number[] }) {
    let url = '/get-noticias';
    return await api.post(url, dto, config);
}
