import React, { useLayoutEffect } from 'react';
import './bottom-navigation.component.scss';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChatIcon from '@mui/icons-material/Chat';
import { useHistory, useLocation } from 'react-router-dom';

function BottomNavigationComponent() {
  const [value, setValue] = React.useState(0);

  let history = useHistory();
  let location = useLocation();

  useLayoutEffect(() => {
    if (window.location.href.indexOf('parceiros') > -1) {
      setValue(0);
    } else if (window.location.href.indexOf('eventos') > -1) {
      setValue(1);
    } else if (window.location.href.indexOf('home') > -1) {
      setValue(2);
    } else if (window.location.href.indexOf('lojas') > -1) {
      setValue(3);
    } else if (window.location.href.indexOf('concierge') > -1) {
      setValue(4);
    }else{
      setValue(10);
    }
  }, [location]);

  const GoPage = (page: string) => {
    history?.push({
      pathname: '/' + page
    })
  }

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        //setValue(newValue);
      }}
    >
      <BottomNavigationAction label="Parceiros" icon={<HandshakeIcon />} onClick={() => { GoPage('parceiros') }} />
      <BottomNavigationAction label="Eventos" icon={<CalendarMonthIcon />} onClick={() => { GoPage('eventos') }} />
      <BottomNavigationAction label="Home" icon={<HomeIcon />} onClick={() => { GoPage('home') }} />
      <BottomNavigationAction label="Lojas" icon={<LocationOnIcon />} onClick={() => { GoPage('lojas') }} />
      <BottomNavigationAction label="Concierge" icon={<ChatIcon />} onClick={() => { GoPage('concierge') }} />
    </BottomNavigation>
  );
}

export default BottomNavigationComponent;
