import React, { useEffect } from 'react';
import { Button, Checkbox, CircularProgress, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import './login.component.scss';
import UsuarioModel from '../../models/usuario.model';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { CadastrarService, LoginService, SalvaTokenService } from '../../services/api-usuario.service';
import { useInput } from '../../hooks/useInput.hook';
import { AccountCircle } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { UsuarioEfetuouLoginService } from '../../services/usuario-efetuou-login.service';
import { getAuthUser } from '../../services/auth';

type LoginComponentProps = {
  tenhoConta: (tenhoConta: boolean) => void;
  email: string;
}

function LoginComponent(props: LoginComponentProps) {

  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput(props.email);
  const { value: senhaInputText, bind: bindSenhaInputText, setValue: setSenhaInputText } = useInput('');
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  let history = useHistory();

  useEffect(() => {
    setEmailInputText(props.email);
  }, [props.email]);

  var dto: any;

  const Logar = (e: any) => {
    e.stopPropagation();

    if (emailInputText === '') {
      SnackService.sendMSG('Informe o e-mail.');
      return;
    }

    if (emailInputText.indexOf('@') == -1 || emailInputText.indexOf('.') == -1) {
      SnackService.sendMSG('Digite um e-mail válido');
      return;
    }

    if (senhaInputText === '') {
      SnackService.sendMSG('Informe a senha.');
      return;
    }

    dto = {
      user_email: emailInputText,
      user_password: senhaInputText
    };

    LoadingService.sendLoadingAtivo(true);

    LoginService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res.data?.errors?.incorrect_password?.length > 0) {
          SnackService.sendMSG('Senha incorreta.');
        } else if (res.data?.errors?.invalid_email?.length > 0) {
          SnackService.sendMSG('Não encontramos cadastro com este e-mail, digitou o e-mail correto?');
        } else if (res.data?.errors?.invalid_username?.length > 0) {
          SnackService.sendMSG('Este usuário não está registrado neste site. Se você não está certo de seu nome de usuário, experimente o endereço de e-mail.');
        } else if (res.data?.dados_us?.data?.user_email?.length > 0) {
          localStorage.setItem("user", JSON.stringify(res.data?.dados_us?.data));
          let tempUser: any = res.data?.dados_us?.data;
          UsuarioEfetuouLoginService.sendMSG(tempUser);
          SalvaToken();
          LimparForm();
          AcessarTutorial();
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });

  }

  const LimparForm = () => {
    setEmailInputText('');
    setSenhaInputText('');
  }

  const AcessarTermo = () => {
    history?.push({
      pathname: `/termos-e-condicoes-de-uso`
    })
  }

  const AcessarTutorial = () => {
    history?.push({
      pathname: `/tutorial`
    })
  }

  const SalvaToken = () => {

    let userTemp: UsuarioModel = getAuthUser();

    dto = {
      user_id: userTemp.ID,
      user_email: userTemp.user_email,
      token: localStorage.getItem('token')
    };

    SalvaTokenService(dto).then((res) => {
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {

      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  return (
    <div className="login-component">
      {
        props.email ?
          <h3>
            1º ACESSO: DIGITE A SENHA RECEBIDA <br />
            EM SEU E-MAIL.
          </h3>
          :
          <h3>
            digite seu e-mail e senha cadastrados
          </h3>
      }
      {
        !props.email &&
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <MailOutlineIcon />
          <TextField {...bindEmailInputText} label="E-mail" variant="standard" />
        </Box>
      }
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <TextField {...bindSenhaInputText} label="Digite a senha" type={showPassword ? "text" : "password"} variant="standard" />
        <Button className="bt-toggle-pass" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </Button>
      </Box>
      <Button onClick={Logar} className={"bt " + 'loading-' + showLoading}>
        <CircularProgress />
        INCIAR SESSÃO
      </Button>
      <FormControlLabel control={<Checkbox defaultChecked />} label={
        <>
          "Li e aceito os <Button onClick={AcessarTermo} className="link">termos e condições gerais</Button>.*"
        </>
      } />
      <div className="flex flex-ch">
        <Button onClick={() => { props.tenhoConta(false) }} className="link">Esqueci a senha</Button>
      </div>
    </div>
  );
}

export default LoginComponent;
