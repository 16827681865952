import React, { useLayoutEffect } from 'react';
import './parceiros.page.scss';
import { LoadingService } from '../../services/loading.service';
import { PageParceirosService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import ParceirosModel from '../../models/parceiros.model';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

function ParceirosPage() {

  let history = useHistory();
  const [listParceiros, setListParceiros] = React.useState<Array<ParceirosModel>>([]);

  useLayoutEffect(() => {
    setTimeout(() => {
      GetParceiros();
    }, 500);
  }, []);

  const GetParceiros = () => {
    LoadingService.sendLoadingAtivo(true);
    PageParceirosService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListParceiros(res.data);
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <section className="page parceiros">
      <div className="container">
        <h1 className='flex flex-cv'>
          <Button className='bt-seta' onClick={() => { history.goBack() }}>
            <ArrowBackIosIcon />
          </Button>
          Parceiros Defender Club
        </h1>
        <div className="cards-parceiros flex flex-ch flex-wr">
          {
            listParceiros ? listParceiros.map((item, indexParceiros) => (
              <div className="card-parceiros flex flex-sp flex-cv" key={indexParceiros}>
                <img src={item.imagem} alt={item.titulo} />
                <div className="txt">
                  <h2>{item.descricao}</h2>
                </div>
              </div>
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>
      </div>
    </section>
  );
}

export default ParceirosPage;
