import Routes from './routes';
import './App.scss';

function App() {

  let url = new URL(window.location.href);
  let location: any = url.searchParams.get("location");
  localStorage.setItem('location', location);

  let appURL: any = '';

  if (localStorage.getItem('appURL')) {
    appURL = localStorage.getItem('appURL');
  } else {
    localStorage.setItem('appURL', window.location.href);
    appURL = window.location.href;
  }

  return (
    <>
      {
        appURL.indexOf('app=1') > -1 &&
        <Routes />
      }
    </>
  );
}

export default App;
