import React, { useEffect } from 'react';
import './login.page.scss';
import CadastroComponent from '../../components/cadastro/cadastro.component';
import logoDefender from '../../assets/login/logo_defender_club.svg';
import LoginComponent from '../../components/login/login.component';
import { useHistory } from 'react-router-dom';

function LoginPage() {
  const [tenhoConta, setTenhoConta] = React.useState<boolean>(false);
  const [primeiroAcesso, setPrimeiroAcesso] = React.useState<string>('');
  let usLogado = localStorage.getItem("user");
  let history = useHistory();

  useEffect(() => {
    document.body.classList.add('is-login-page');
    if (usLogado) {
      AcessarHome();
    }

    return function () {
      document.body.classList.remove('is-login-page');
    };
  }, []);

  const AcessarHome = () => {
    history?.push({
      pathname: `/home`
    })
  }

  return (
    <section className={"login-page flex flex-bv tenho-conta-" + tenhoConta}>
      <div className="container">
        <div className='box-logo'>
          <img src={logoDefender} alt="Logo Defender" />
        </div>
        <CadastroComponent
          tenhoConta={(tenhoConta) => { setTenhoConta(tenhoConta) }}
          primeiroAcesso={(primeiroAcesso) => { setPrimeiroAcesso(primeiroAcesso) }}
        />
        <LoginComponent
          tenhoConta={(tenhoConta) => { setTenhoConta(tenhoConta) }}
          email={primeiroAcesso}
        />
      </div>
    </section>
  );
}

export default LoginPage;
