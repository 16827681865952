import React from 'react';
import { Button, CircularProgress, InputAdornment, TextField } from '@mui/material';
import './cadastro.component.scss';
import UsuarioModel from '../../models/usuario.model';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { CadastrarService, VerificaEmailService } from '../../services/api-usuario.service';
import { useInput } from '../../hooks/useInput.hook';
import { AccountCircle } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';

type CadastroComponentProps = {
  tenhoConta: (tenhoConta: boolean) => void;
  primeiroAcesso: (primeiroAcesso: string) => void;
}

function CadastroComponent(props: CadastroComponentProps) {

  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput('');
  const { value: nomeCompletoInputText, bind: bindNomeCompletoInputText, setValue: setNomeCompletoInputText } = useInput('');
  const { value: dealerInputText, bind: bindDealerInputText, setValue: setDealerInputText } = useInput('');
  const { value: cidadeInputText, bind: bindCidadeInputText, setValue: setCidadeInputText } = useInput('');
  const { value: estadoInputText, bind: bindEstadoInputText, setValue: setEstadoInputText } = useInput('');
  const [showLoading, setShowLoading] = React.useState<boolean>(false);

  var dto: any;

  const Cadastrar = (e: any) => {
    e.stopPropagation();

    if (!emailInputText) {
      SnackService.sendMSG('O campo e-mail é obrigatório');
      return;
    } else if (emailInputText.indexOf('@') == -1 || emailInputText.indexOf('.') == -1) {
      SnackService.sendMSG('Digite um e-mail válido');
      return;
    }

    dto = {
      email: emailInputText
    };

    LoadingService.sendLoadingAtivo(true);

    VerificaEmailService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res?.data.length > 0) {
          CadastrarAposVerificarEmail();
        } else {
          SnackService.sendMSG('E-mail não encontrado, use o mesmo e-mail cadastrado na sua concessionária.');
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });

    // setShowLoading(true);

    // setTimeout(() => {
    //   setShowLoading(false);
    //   props.primeiroAcesso(emailInputText);
    //   props.tenhoConta(true);
    // }, 2000);
  }

  const CadastrarAposVerificarEmail = () => {
    dto = {
      user_email: emailInputText
    };

    LoadingService.sendLoadingAtivo(true);

    CadastrarService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res?.data?.email_exists) {
          SnackService.sendMSG(res?.data?.email_exists);
        } else if (res?.data?.random_password) {
          SnackService.sendMSG('Usuário cadastrado, enviamos um e-mail para ' + dto.user_email + ' com a sua senha. *Se não receber imediatamente, verifique em sua caixa de spam.');
          LimparForm();
          setShowLoading(false);
          props.primeiroAcesso(emailInputText);
          props.tenhoConta(true);
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const LimparForm = () => {
    setEmailInputText('');
  }

  return (
    <div className="cadastro-component">
      <h3>UTILIZE O E-MAIL CADASTRADO NA SUA CONCESSIONÁRIA DE RELACIONAMENTO.</h3>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <MailOutlineIcon />
        <TextField {...bindEmailInputText} label="E-mail" variant="standard" />
      </Box>
      <Button onClick={Cadastrar} className={"bt " + 'loading-' + showLoading}>
        <CircularProgress />
        Receber senha
      </Button>
      <div className="flex flex-ch">
        <Button onClick={() => {
          props.primeiroAcesso('');
          props.tenhoConta(true);
        }} className="link">Já tenho conta</Button>
      </div>
    </div>
  );
}

export default CadastroComponent;
