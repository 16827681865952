import React, { useLayoutEffect } from 'react';
import './interna-eventos.page.scss';
import { useHistory, useParams } from 'react-router-dom';
import { EventoService } from '../../services/eventos.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import EventosModel from '../../models/eventos.model';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';

function InternaEventosPage() {

  let { AliasEvento } = useParams<{ AliasEvento: string }>();
  const [itemEvento, setItemEvento] = React.useState<EventosModel>();
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetEvento();
    }, 500);
  }, []);

  const GetEvento = () => {
    LoadingService.sendLoadingAtivo(true);
    EventoService({ alias: AliasEvento }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setItemEvento(res.data[0]);
        console.log(res.data[0], 'res.data[0]');
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      {
        itemEvento &&
        <section className="interna-eventos page">
          <div className="container">
            <h1 className='flex flex-cv'>
              <Button className='bt-seta' onClick={() => { history.goBack() }}>
                <ArrowBackIosIcon />
              </Button>
            </h1>
            <img src={itemEvento?.imagem} alt={itemEvento?.titulo} />
            <h2>{itemEvento?.titulo}</h2>
            <h3 className='flex flex-cv'>
              <span>
                <CalendarMonthIcon />
                <span>eventos</span>
              </span>
              {itemEvento.data}
            </h3>
            {
              itemEvento.tags &&
              <div className="tags">
                {
                  itemEvento.tags.map((itemTag, indexTag) => (
                    <span key={indexTag}>{itemTag} </span>
                  ))
                }
              </div>
            }
            <div className="descricao" dangerouslySetInnerHTML={{ __html: itemEvento.descricao ? itemEvento.descricao : '' }}></div>
            <h5>Informações e datas:</h5>
            <h4>
              <CalendarMonthIcon /> {itemEvento.data}
            </h4>
            {
              itemEvento.data_inscricao &&
              <h4>
                <NoteAltIcon /> {itemEvento.data_inscricao}
              </h4>
            }
          </div>
        </section>
      }
    </>
  );
}

export default InternaEventosPage;
