
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import NotFoundPage from './pages/not-found/not-found.page';
import HomePage from './pages/home/home.page';
import EventosPage from './pages/eventos/eventos.page';
import SnackComponent from './components/snack/snack.component';
import ProtectedRoute from './protected-route';
import LoginPage from './pages/login/login.page';
import TermosPage from './pages/termos/termos.page';
import LoadingComponent from './components/loading/loading.component';
import BottomNavigationComponent from './components/bottom-navigation/bottom-navigation.component';
import ParceirosPage from './pages/parceiros/parceiros.page';
import TutorialPage from './pages/tutorial/tutorial.page';
import PreferenciaPage from './pages/preferencia/preferencia.page';
import MeuPerfilPage from './pages/meu-perfil/meu-perfil.page';
import LojasPage from './pages/lojas/lojas.page';
import ConciergePage from './pages/concierge/concierge.page';
import InternaEventosPage from './pages/interna-eventos/interna-eventos.page';
import InternaNoticiasPage from './pages/interna-noticias/interna-noticias.page';
import VideosPage from './pages/videos/videos.page';
import NoticiasPage from './pages/noticias/noticias.page';

function Routes() {

    return (
        <BrowserRouter>
            <HeaderComponent />
            <Switch>
                <Route exact path="/" component={LoginPage} />
                <Route exact path="/login" component={LoginPage} />
                <ProtectedRoute
                    exact={true}
                    path={'/home'}
                    component={HomePage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/eventos'}
                    component={EventosPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/videos'}
                    component={VideosPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/noticias'}
                    component={NoticiasPage}
                />
                <ProtectedRoute
                    exact={true}
                    path="/evento/:AliasEvento"
                    component={InternaEventosPage}
                />
                <ProtectedRoute
                    exact={true}
                    path="/noticia/:AliasNoticia"
                    component={InternaNoticiasPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/parceiros'}
                    component={ParceirosPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/tutorial'}
                    component={TutorialPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/preferencia'}
                    component={PreferenciaPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/meu-perfil'}
                    component={MeuPerfilPage}
                />

                <ProtectedRoute
                    exact={true}
                    path={'/lojas'}
                    component={LojasPage}
                />

                <ProtectedRoute
                    exact={true}
                    path={'/concierge'}
                    component={ConciergePage}
                />
                <Route exact path="/termos-e-condicoes-de-uso" component={TermosPage} />

                <Route component={NotFoundPage} />
            </Switch>
            <SnackComponent />
            <LoadingComponent />
            <BottomNavigationComponent />
        </BrowserRouter>
    )
}

export default Routes;