import React, { useLayoutEffect } from 'react';
import { LoadingService } from '../../services/loading.service';
import './home.page.scss';
import { GetEventosService, GetNoticiasService, GetVideosService } from '../../services/eventos.service';
import EventosModel from '../../models/eventos.model';
import { SnackService } from '../../services/snack.service';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Button, Modal } from '@mui/material';
import VideosModel from '../../models/videos.model';
import NoticiasModel from '../../models/noticias.model';
import { DestaqueHomeService, PageTermoService } from '../../services/pages.service';
import DestaqueHomeModel from '../../models/destaque-home.model';
import { useHistory } from 'react-router-dom';

export default function HomePage() {

  const [destaque, setDestaque] = React.useState<DestaqueHomeModel>();

  const [listIDsEventos, setListIDsEventos] = React.useState<Array<number>>([]);
  const [listEventos, setListEventos] = React.useState<Array<EventosModel>>([]);
  const [acabouListEventos, setAcabouListEventos] = React.useState<boolean>(false);

  const [listIDsVideos, setListIDsVideos] = React.useState<Array<number>>([]);
  const [listVideos, setListVideos] = React.useState<Array<VideosModel>>([]);
  const [acabouListVideos, setAcabouListVideos] = React.useState<boolean>(false);
  const [videoAtual, setVideoAtual] = React.useState<string>('');
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const [listIDsNoticias, setListIDsNoticias] = React.useState<Array<number>>([]);
  const [listNoticias, setListNoticias] = React.useState<Array<NoticiasModel>>([]);
  const [acabouListNoticias, setAcabouListNoticias] = React.useState<boolean>(false);

  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetDestaque();
    }, 500);
  }, []);

  const GetDestaque = () => {
    LoadingService.sendLoadingAtivo(true);
    DestaqueHomeService().then(res => {
      GetEventos();
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setDestaque(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      GetEventos();
      console.log(err);
    });
  }

  const GetEventos = () => {
    GetEventosService({ user_email: 'email', idsEventos: listIDsEventos }).then(res => {
      GetVideos();
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDsEventos(listIDsEventos.concat(res.data.idsArr));
        setListEventos(listEventos.concat(res.data.eventosArr));
        if (res.data.cursosArr.length < 5) {
          setAcabouListEventos(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      GetVideos();
      console.log(err);
    });
  }

  const GetVideos = () => {
    GetVideosService({ user_email: 'email', idsVideos: listIDsVideos }).then(res => {
      GetNoticias();
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDsVideos(listIDsVideos.concat(res.data.idsArr));
        setListVideos(listVideos.concat(res.data.videosArr));
        if (res.data.videosArr.length < 5) {
          setAcabouListVideos(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      GetNoticias();
      console.log(err);
    });
  }

  const GetNoticias = () => {
    GetNoticiasService({ user_email: 'email', idsNoticias: listIDsNoticias }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDsNoticias(listIDsNoticias.concat(res.data.idsArr));
        setListNoticias(listNoticias.concat(res.data.noticiasArr));
        if (res.data.noticiasArr.length < 5) {
          setAcabouListNoticias(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const AcessarEvento = (alias: string) => {
    history?.push({
      pathname: `/evento/${alias}`
    })
  }

  const AcessarNoticia = (alias: string) => {
    history?.push({
      pathname: `/noticia/${alias}`
    })
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  return (
    <>
      <section className="page home">
        <div className="container">
          <div className="box-destaque">
            <div className="img" style={{ backgroundImage: 'url(' + destaque?.imagem + ')' }}></div>
            <h2>{destaque?.titulo}</h2>
            <div dangerouslySetInnerHTML={{ __html: destaque?.descricao ? destaque.descricao : '' }}></div>
          </div>
          <div className="box-eventos">
            <div className="titulo flex flex-sp flex-cv">
              <h3>Eventos</h3>
              <Button className='link cinza'>Ver todos</Button>
            </div>
            <div className="cards-eventos flex flex-ch">
              {
                listEventos ? listEventos.map((item, indexCurso) => (
                  <div onClick={() => { AcessarEvento(item.alias) }} className="card-eventos flex" key={indexCurso}>
                    <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                    <div className="txt">
                      <h2>{item.titulo}</h2>
                      <h3 className='flex flex-cv'>
                        <span>
                          <CalendarMonthIcon />
                          <span>eventos</span>
                        </span>
                        {item.data}
                      </h3>
                      {
                        item.tags &&
                        <div className="tags">
                          {
                            item.tags.map((itemTag, indexTag) => (
                              <span key={indexTag}>{itemTag} </span>
                            ))
                          }
                        </div>
                      }
                    </div>
                  </div>
                )) : <span>Nenhum item encontrado.</span>
              }
            </div>
          </div>

          <div className="box-videos">
            <div className="titulo flex flex-sp flex-cv">
              <h3>Vídeos</h3>
              <Button onClick={() => { GoPage('videos') }} className='link cinza'>Ver todos</Button>
            </div>
            <div className="cards-videos flex flex-ch">
              {
                listVideos ? listVideos.map((item, indexVideos) => (
                  <div className="card-videos flex" key={indexVideos}
                    onClick={() => {
                      setVideoAtual(item.video);
                      setOpenModal(true);
                    }}>
                    <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                    <div className="txt">
                      <h2>{item.titulo}</h2>
                      <div className="desc" dangerouslySetInnerHTML={{ __html: item.descricao ? item.descricao : '' }}></div>
                    </div>
                  </div>
                )) : <span>Nenhum item encontrado.</span>
              }
            </div>
          </div>

          <div className="box-noticias">
            <div className="titulo flex flex-sp flex-cv">
              <h3>Notícias</h3>
              <Button onClick={() => { GoPage('noticias') }} className='link cinza'>Ver todos</Button>
            </div>
            <div className="cards-noticias flex flex-ch">
              {
                listNoticias ? listNoticias.map((item, indexNoticias) => (
                  <div onClick={() => { AcessarNoticia(item.alias) }} className="card-noticias flex" key={indexNoticias}>
                    <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                    <div className="txt">
                      <h2>{item.titulo}</h2>
                      {/* <div className="desc" dangerouslySetInnerHTML={{ __html: item.descricao ? item.descricao : '' }}></div> */}
                    </div>
                  </div>
                )) : <span>Nenhum item encontrado.</span>
              }
            </div>
          </div>

          <Modal
            open={openModal}
            onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='modal-video'
          >
            <Box>
              {
                videoAtual &&
                <video src={videoAtual} controls></video>
              }
            </Box>
          </Modal>
        </div>
      </section>
    </>
  );
}