import React, { useLayoutEffect } from 'react';
import './videos.page.scss';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import VideosModel from '../../models/videos.model';
import { useHistory } from 'react-router-dom';
import { Box, Button, Modal } from '@mui/material';
import { GetVideosService } from '../../services/eventos.service';
import AddIcon from '@mui/icons-material/Add';

function VideosPage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listVideos, setListVideos] = React.useState<Array<VideosModel>>([]);
  const [acabouListVideos, setAcabouListVideos] = React.useState<boolean>(false);
  let history = useHistory();

  const [videoAtual, setVideoAtual] = React.useState<string>('');
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      GetVideos();
    }, 500);
  }, []);

  const GetVideos = () => {
    LoadingService.sendLoadingAtivo(true);
    GetVideosService({ user_email: 'email', idsVideos: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListVideos(listVideos.concat(res.data.videosArr));
        if (res.data.videosArr.length < 3) {
          setAcabouListVideos(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar videos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <section className="page videos">
        <div className="head">
          <div className="container">
            <div className="flex flex-cv flex-sp">

            </div>
          </div>
        </div>
        <div className="container">
          <div className="cards-videos flex flex-ch flex-wr">
            {
              listVideos ? listVideos.map((item, indexVideo) => (
                <div
                  className="card-videos flex"
                  key={indexVideo}
                  onClick={() => {
                    setVideoAtual(item.video);
                    setOpenModal(true);
                  }}
                >
                  <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                  <div className="txt">
                    <h2>{item.titulo}</h2>
                    <div className="descricao" dangerouslySetInnerHTML={{ __html: item.descricao ? item.descricao : '' }}></div>
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListVideos) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt cinza' onClick={GetVideos}>
                <AddIcon />
                vídeos...
              </Button>
            </div>
          }
        </div>
      </section>
      <Modal
        open={openModal}
        onClose={() => { setOpenModal(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modal-video'
      >
        <Box>
          {
            videoAtual &&
            <video src={videoAtual} controls></video>
          }
        </Box>
      </Modal>
    </>
  );
}

export default VideosPage;
