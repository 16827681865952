import React, { useLayoutEffect } from 'react';
import './interna-noticias.page.scss';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import NoticiasModel from '../../models/eventos.model';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import { NoticiaService } from '../../services/pages.service';

function InternaNoticiasPage() {

  let { AliasNoticia } = useParams<{ AliasNoticia: string }>();
  const [itemNoticia, setItemNoticia] = React.useState<NoticiasModel>();
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetNoticia();
    }, 500);
  }, []);

  const GetNoticia = () => {
    LoadingService.sendLoadingAtivo(true);
    NoticiaService({ alias: AliasNoticia }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setItemNoticia(res.data[0]);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      {
        itemNoticia &&
        <section className="interna-noticia page">
          <div className="container">
            <h1 className='flex flex-cv'>
              <Button className='bt-seta' onClick={() => { history.goBack() }}>
                <ArrowBackIosIcon />
              </Button>
            </h1>
            <img src={itemNoticia?.imagem} alt={itemNoticia?.titulo} />
            <h2>{itemNoticia?.titulo}</h2>
            <div className="descricao" dangerouslySetInnerHTML={{ __html: itemNoticia.descricao ? itemNoticia.descricao : '' }}></div>
          </div>
        </section>
      }
    </>
  );
}

export default InternaNoticiasPage;
