import React, { useEffect } from 'react';
import './preferencia.page.scss';
import { Button, Switch, TextField } from '@mui/material';
import { LoadingService } from '../../services/loading.service';
import { GetPreferenciasService, PageCategoriasService, SalvarPreferenciasService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import PreferenciasModel from '../../models/preferencias.model';
import { useInput } from '../../hooks/useInput.hook';
import { useHistory } from 'react-router-dom';
import UsuarioModel from '../../models/usuario.model';
import { getAuthUser } from '../../services/auth';

function PreferenciaPage() {
  const [usuario, setUsuario] = React.useState<UsuarioModel>(getAuthUser());
  const [itensPreferencia, setItensPreferencia] = React.useState<PreferenciasModel[]>([]);
  const [itensPreferenciaMesclado, setItensPreferenciaMesclado] = React.useState<PreferenciasModel[]>([]);
  const { value: outrosInputText, bind: bindOutrosInputText, setValue: setOutrosInputText } = useInput('');
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      GetCategorias();
    }, 500);
  }, []);

  useEffect(() => {
    //console.log(JSON.stringify(itensPreferencia), ' itens');
    GetPreferencias();
  }, [itensPreferencia]);

  const GetCategorias = () => {
    LoadingService.sendLoadingAtivo(true);
    PageCategoriasService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setItensPreferencia(res.data);
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const GetPreferencias = () => {
    let dto = {
      ID: usuario.ID
    }
    LoadingService.sendLoadingAtivo(true);
    GetPreferenciasService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        let newArrPref: PreferenciasModel[] = [];
        let itensPrefUser: PreferenciasModel[] = res.data.dados_us.preferencias == '' ? [] : JSON.parse(res.data.dados_us.preferencias);
        if (itensPrefUser.length > 0) {
          console.log(itensPrefUser, 'itensPrefUser');
          itensPrefUser.map((item: PreferenciasModel, indexPref: number) => {
            if (itensPreferencia[indexPref]) {
              if (item.slug == itensPreferencia[indexPref].slug) {
                newArrPref.push(item);
              } else {
                newArrPref.push(itensPreferencia[indexPref]);
              }
            }
          })
          //setItensPreferencia(newArrPref);
          setItensPreferenciaMesclado([
            ...newArrPref
          ]);
        } else {
          setItensPreferenciaMesclado([
            ...itensPreferencia
          ]);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const SalvarPreferencias = (preferencias: string) => {
    let dto = {
      ID: usuario.ID,
      preferencias: preferencias
    }
    LoadingService.sendLoadingAtivo(true);
    SalvarPreferenciasService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        //setItensPreferencia(res.data);
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {

    itensPreferenciaMesclado[event.target.name].status = event.target.checked;

    setItensPreferenciaMesclado([
      ...itensPreferenciaMesclado
    ]);

    SalvarPreferencias(JSON.stringify(itensPreferenciaMesclado));
  };

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/` + page
    })
  }

  return (
    <>
      {
        itensPreferenciaMesclado &&
        <section className="page preferencia">
          <div className="container">
            <h1>Bem-vindo Daniel!</h1>
            <h2>Escolha ao menos 3 categorias de interesse para iniciar:</h2>
            <div className="itens flex flex-sp flex-wr">
              {
                itensPreferenciaMesclado.map((item, indexPreferencias) => (
                  <label key={indexPreferencias} className='flex flex-cv'>
                    <Switch checked={item.status} onChange={handleChangeSwitch} name={indexPreferencias + ''} color="default" /> {item.name}
                  </label>
                ))
              }
            </div>
            <TextField {...bindOutrosInputText} label="Outros:" variant="standard" />
            <Button onClick={() => { GoPage('home') }} className="bt cinza">continuar</Button>
          </div>
        </section>
      }
    </>
  );
}

export default PreferenciaPage;
