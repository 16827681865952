import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function PageTermoService() {
    let url = '/termos-e-condicoes-de-uso';
    return await api.post(url, { email: 'defender@' }, config);
}

export async function DestaqueHomeService() {
    let url = '/destaque-home';
    return await api.post(url, { email: 'defender@' }, config);
}

export async function PageParceirosService() {
    let url = '/get-parceiros';
    return await api.post(url, { email: 'defender@' }, config);
}

export async function PageTutorialService() {
    let url = '/tutorial';
    return await api.post(url, { email: 'defender@' }, config);
}

export async function PageCategoriasService() {
    let url = '/get-categorias';
    return await api.post(url, { email: 'defender@' }, config);
}

export async function PageLojasService() {
    let url = '/get-lojas';
    return await api.post(url, { email: 'defender@' }, config);
}

export async function NoticiaService(dto: { alias: string }) {
    let url = '/get-noticia';
    return await api.post(url, dto, config);
}

export async function SalvarPreferenciasService(dto: { ID: string, preferencias: string }) {
    let url = '/salvar-preferencia';
    return await api.post(url, dto, config);
}

export async function GetPreferenciasService(dto: { ID: string }) {
    let url = '/get-preferencia';
    return await api.post(url, dto, config);
}