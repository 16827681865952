import React, { useLayoutEffect } from 'react';
import './tutorial.page.scss';
import { LoadingService } from '../../services/loading.service';
import { PageTutorialService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import TutorialModel from '../../models/tutorial.model';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

function TutorialPage() {

  const [listTutoriais, setListTutoriais] = React.useState<Array<TutorialModel>>([]);
  let history = useHistory();

  useLayoutEffect(() => {
    document.body.classList.add('is-tutorial-page');
    setTimeout(() => {
      GetTutorial();
    }, 500);

    return function () {
      document.body.classList.remove('is-tutorial-page');
    };
  }, []);

  const GetTutorial = () => {
    LoadingService.sendLoadingAtivo(true);
    PageTutorialService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListTutoriais(res.data);
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/` + page
    })
  }

  return (
    <>
      {
        listTutoriais.length > 0 &&
        <section className="page tutorial">
          <OwlCarousel
            className='owl-theme'
            loop
            margin={10}
            nav={false}
            responsive={{
              0: {
                items: 1,
              },
              450: {
                items: 1,
              },
              600: {
                items: 1,
              },
              1000: {
                items: 2,
              },
            }}
          >
            {
              listTutoriais.map((item, indexParceiros) => (
                <div key={indexParceiros}>
                  <div className="img" style={{ backgroundImage: "url(" + item.imagem + ")" }}></div>
                  <div className="container-fluid txt">
                    <h2>{item.titulo}</h2>
                    <h3>{item.descricao}</h3>
                  </div>
                </div>
              ))
            }
          </OwlCarousel>
          <div className="container-fluid flex flex-wr flex-ch">
            <Button className='bt cinza' onClick={() => { GoPage('preferencia') }}>Continuar</Button>
            <Button className='link cinza' onClick={() => { GoPage('home') }}>Ver depois</Button>
          </div>
        </section>
      }
    </>
  );
}

export default TutorialPage;
