import React, { useLayoutEffect } from 'react';
import './eventos.page.scss';
import { LoadingService } from '../../services/loading.service';
import { GetEventosService } from '../../services/eventos.service';
import { SnackService } from '../../services/snack.service';
import EventosModel from '../../models/eventos.model';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function EventosPage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listEventos, setListCursos] = React.useState<Array<EventosModel>>([]);
  const [acabouListEventos, setAcabouListEventos] = React.useState<boolean>(false);
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetEventos();
    }, 500);
  }, []);

  const GetEventos = () => {
    LoadingService.sendLoadingAtivo(true);
    GetEventosService({ user_email: 'email', idsEventos: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListCursos(listEventos.concat(res.data.eventosArr));
        if (res.data.eventosArr.length < 3) {
          setAcabouListEventos(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const AcessarEvento = (alias: string) => {
    history?.push({
      pathname: `/evento/${alias}`
    })
  }

  return (
    <>
      <section className="page eventos">
        <div className="head">
          <div className="container">
            <div className="flex flex-cv flex-sp">

            </div>
          </div>
        </div>
        <div className="container">
          <div className="cards-eventos flex flex-ch flex-wr">
            {
              listEventos ? listEventos.map((item, indexCurso) => (
                <div onClick={() => { AcessarEvento(item.alias) }} className="card-eventos flex" key={indexCurso}>
                  <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                  <div className="txt">
                    <h2>{item.titulo}</h2>
                    <h3 className='flex flex-cv'>
                      <span>
                        <CalendarMonthIcon />
                        <span>eventos</span>
                      </span>
                      {item.data}
                    </h3>
                    {
                      item.tags &&
                      <div className="tags">
                        {
                          item.tags.map((itemTag, indexTag) => (
                            <span key={indexTag}>{itemTag} </span>
                          ))
                        }
                      </div>
                    }
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListEventos) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt cinza' onClick={GetEventos}>
                <AddIcon />
                eventos...
              </Button>
            </div>
          }
        </div>
      </section>
    </>
  );
}

export default EventosPage;
